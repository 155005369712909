<template>
  <div>
    <PageLoader v-bind:storage="appLoading" />
    <ServerError v-if="ServerError" />
    <v-snackbar v-model="snackbar" color="#3CB043" right :timeout="3000">
      <v-layout wrap>
        <v-flex text-left class="align-self-center">{{ msg }}</v-flex>
        <v-flex text-right>
          <v-btn small :ripple="false" text @click="snackbar = false">
            <v-icon style="color: white">mdi-close</v-icon>
          </v-btn>
        </v-flex>
      </v-layout>
    </v-snackbar>
    <v-layout wrap justify-center align-center fill-height py-16>
      <v-flex xs12 sm8 md5 lg4 align-self-center>
        <v-card rounded="xl" outlined elevation="0">
          <v-layout wrap justify-center>
            <v-flex xs12 py-8>
              <span
                style="
                  font-family: poppinsbold;
                  font-size: 25px;
                  color: #5a5a5a;
                "
                >Forgot password ?
              </span>
            </v-flex>
          </v-layout>
          <v-layout wrap justify-center>
            <v-flex xs10 sm10 text-left>
              <v-layout wrap>
                <v-flex text-left>
                  <span style="font-family: poppinsregular; font-size: 14px"
                    >Enter your email and we'll send you a link to reset your
                    password.</span
                  >
                </v-flex>
                <v-flex pt-2>
                  <span style="font-family: poppinsregular; font-size: 13px"
                    >Email</span
                  >
                  <v-text-field
                    outlined
                    dense
                    v-model="email"
                    placeholder="Email"
                  ></v-text-field>
                </v-flex>
              </v-layout>
            </v-flex>
            <v-flex xs10 sm10 py-3>
              <v-btn block color="#3CB043" dark @click="sentOTP()">
                <span
                  style="
                    font-size: 14px;
                    font-family: poppinssemibold;
                    text-transform: none;
                  "
                  >Submit</span
                >
              </v-btn>
            </v-flex>
            <v-flex xs10 py-3>
              <span style="font-family: opensanssemibold; font-size: 13px"
                >Back to
              </span>
              <router-link to="/loginForm">
                <span
                  style="
                    font-family: opensanssemibold;
                    font-size: 13px;
                    color: #3cb043;
                  "
                  >Login</span
                >
              </router-link>
            </v-flex>
          </v-layout>
          <v-layout wrap py-5 v-if="status == true">
            <v-flex xs12>
              <span style="font-family: poppinsbold"
                >Please check your email</span
              >
            </v-flex>
          </v-layout>
        </v-card>
      </v-flex>
    </v-layout>
  </div>
</template>
<script>
// import VieOtpInput from "@bachdgvn/vue-otp-input";
import axios from "axios";
export default {
  components: {
    // VieOtpInput,
  },
  data() {
    return {
      appLoading: false,
      ServerError: false,
      snackbar: false,
      timeout: 5000,
      msg: null,
      msg2: null,
      phone: null,
      otp: null,
      timeLeft: "00:00",
      intervalTimer: null,
      otpexpired: false,
      email: "",
      status: "",
      password: "",
      confirmPassword: "",
    };
  },
  methods: {
    // validateOTP(value) {
    //   this.otp = value;
    // },
    sentOTP() {
      var userData = {};
      userData["email"] = this.email;
      this.appLoading = true;
      axios({
        method: "POST",
        url: "/user/forgot/password/link",
        data: userData,
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status) {
            this.status = response.data.status;
            this.msg = "Email Sent Successfully";
            this.snackbar = true;
          } else {
            this.msg = response.data.msg;
            this.snackbar = true;
          }
        })
        .catch((err) => {
          this.ServerError = true;
          console.log(err);
        });
    },
  },
};
</script>
<style scoped>
.centered-input >>> input {
  text-align: center;
}
</style>
  